
.card-info{
    width: 20rem;
}

.card-info:hover{
    box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253)
}


