.container-login-data .row{
    background: white;
    border-radius: 30px;
    box-shadow: 12px 12px 22px grey;
}

img{
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.btn1{
    border: none;
    outline: none;
    height: 50px;
    width: 100%;
    background-color: black;
    color: white;
    border-radius: 4px;
    font-weight: bold;
}

.btn1:hover{
    background: white;
    border: 1px solid;
    color: black;
}