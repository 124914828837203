@font-face {
    font-family: Nunito;
    src: url(../../public/fonts/NunitoSans-Italic-VariableFont_YTLC\,opsz\,wdth\,wght.ttf);
}

.font-tittle{
    font-family: Nunito Sans;
    font-weight: 800;
}

.textColor{
    color: #26bde2;
}

.textColorMember{
    color: rgb(252,195,11);
}