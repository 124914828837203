.navbar{
    background-color: #26bde2;
    width: 100vw;
    font-size: 15px;
    margin: 0;
    padding: 0;
    justify-items: center;
    align-items: center;
}

.navbar-collapse{
    justify-content: end;
}