.card-container{
    width: 300px;
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    transition: 0.3s;
    animation: ease-in;
}

.card-container:hover{
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
}

.image-container img{
    overflow: hidden;
    height: 200px;
}

.card-content{
    margin: 1rem;
    margin-top: 0.5rem;
}

.card-title{
    margin-bottom: 0.5rem;
}
h3,p{
    margin: 0;
    padding: 0;
}

